body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Need this if you want dark theme to work on fullscreen in safari. */
body,
html {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading_text:after {
  animation: dots 2s linear infinite;
  content: " .";
}

@keyframes dots {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  90%,
  100% {
    content: "";
  }
}

/* Fix appearance of SNMP tab on medium-sized screens.*/
@media screen and (min-width: 1800px) {
  div .deviceSnmpPaper {
    max-width: 400px;
  }
  div .deviceSnmpInfoContainer {
    max-width: 440px;
    flex-direction: column;
  }

  div .snmpContainer {
    gap: 10px;
    flex-direction: column;
  }
}

@media screen and (max-width: 1800px) {
  div .snmpContainer {
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  div .snmpInfoPaper,
  div .snmpWalkPaper {
    flex-grow: 1;
  }
  div .colReverseSmallScreens {
    flex-direction: column-reverse;
  }
  div .deviceSnmpInfoContainer {
    flex-direction: row-reverse;
  }
}
